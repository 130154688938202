import { getWelfarePrivateOverviewApi, getWelfarePublicOverviewApi } from '@/core/api';
import { Account } from '@/core/shared';
import { resso } from '@/core/store';
import { useEffect } from 'react';
import { useRequestData } from './use-request-data';

const store = resso({
  assist: {
    requiredInvites: 0,
    invitorPrizeValue: 0,
    invitedPrizeValue: 0,
    enable: false,
    currency: 'USDT',
  },
  luckydraw: {
    prizeValue: 0,
    enable: false,
  },
  mysterybox: {
    enable: false,
  },
  hasData: false,
});
export const useSharedPromoData = () => {
  const [, fetchPublicOverviewData] = useRequestData(getWelfarePublicOverviewApi, {
    fetchOnMount: false,
    successCallback(data) {
      store.assist = data.assist;
      store.luckydraw = data.luckydraw;
      store.mysterybox = data.mysterybox;
    },
  });
  const [, fetchPrivateOverviewData] = useRequestData(getWelfarePrivateOverviewApi, {
    fetchOnMountWhileLogin: true,
    fetchOnMount: false,
    successCallback(data) {
      store.assist = data.assist;
      store.luckydraw = data.luckydraw;
      store.mysterybox = data.mysterybox;
    },
  });
  useEffect(() => {
    if (!store.hasData) {
      const isLogin = Account.isLogin;
      if (isLogin) {
        fetchPrivateOverviewData();
      } else {
        fetchPublicOverviewData();
      }
      store.hasData = true;
    }
  }, [store.hasData]);

  return {
    assist: store.assist,
    luckydraw: store.luckydraw,
    mysterybox: store.mysterybox,
  };
};
